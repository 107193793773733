import { IconUIInfoCircleImageUrl, makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ZipCodeQuestion' })((theme) => ({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
      borderColor: theme.palette.grey[600],
    },
    '& .MuiFormHelperText-root.Mui-error': {
      ...theme.typography.body1,
      borderRadius: 3,
      border: `2px solid ${theme.palette.error.main}`,
      display: 'flex',
      margin: 0,
      padding: 10,
      color: theme.palette.error.darkText,
      backgroundColor: theme.palette.error.light,
      marginTop: 40,
      marginBottom: -20,
      '&:before': {
        content: '""',
        display: 'block',
        maskImage: `url(${IconUIInfoCircleImageUrl})`,
        height: 20,
        width: 20,
        paddingLeft: 5,
        margin: '-2px 15px 0 0px',
        backgroundColor: theme.palette.error.dark,
      },
    },
  },
}));
